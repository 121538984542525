module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://tools.focus-home.com/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Insurgency: Sandstorm","short_name":"Beta Insurgency Sandstorm","start_url":"/","background_color":"#ffffff","theme_color":"#0b161a","display":"minimal-ui","icon":"src/images/meta.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0852c51663a46092feaaa4c98605bfa9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
